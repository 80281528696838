* {
  font-family: Arial, Helvetica, sans-serif;
}

.fw-600 {
  font-weight: 600;
}

.px-6 {
  padding: 0 100px;
}

.img-16-9 {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.img-1-1 {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.fst-italic {
  font-style: italic;
}

.exhibition__image {
  width: 25%;
}

.jaewonpost {
  width: 50vw;
}

@media (max-width: 767px) {
  .jaewonpost {
    width: 75vw;
  }
  .mobile-width {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .mobile-padding {
    padding: 50px 0 !important;
  }
  .mobile-h {
    font-size: 10px;
  }
  .mobile-h1 {
    font-size: 15px;
  }
  .mobile-h-p {
    padding: 10px !important;
  }
  .mobile-width-75 {
    width: 75% !important;
  }

  .exhibition__image {
    width: 100%;
  }
}
